body {

  overflow: hidden;
}
a{
  text-decoration: none;
}
a:visited{
  color:#F1F2F6;
}
.App {
  text-align: center;
  background-color: #0032a0;
}

.gradient{
  background-color: #C9C6C6;
  background-image: linear-gradient(225deg, #2D3436 0%, #F1F2F6 74%);
  background-size: cover;
  background-position: center;
}

.Rose {
  background-color: #C9C6C6;
  background-image: linear-gradient(225deg, #2D3436 0%, #F1F2F6 74%);
  background-size: cover;
  background-position: center;
  mask: url(centre_rose.svg);
  mask-size: 100% 100%;
  position: absolute;
  margin: auto;
  height: 100%;
  width: auto;
  pointer-events: none;
}
.rotates{
  animation: Rose-spin-back 1 1s;
}
@media (prefers-reduced-motion: no-preference) and (orientation: portrait) {
  .rotates {
    animation: Rose-spin 1 1s;
    transform: rotate(90deg);
  }
}

.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: absolute;
  left:0;
  bottom:0;
  right:0;
  font-size: 3vh;
  text-shadow:0 0 2px #0032a0;
  -webkit-text-fill-color: #F1F2F6;
  -webkit-background-clip: text;
}
.footer-right{
  flex:15%;
  text-align:right;
}
.social{
  margin-right:5pt;
}

@keyframes Rose-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}
@keyframes Rose-spin-back {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(0deg);
  }
}


